import React, { useState, useEffect, useRef, useContext } from 'react';
import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Store, StoreProvider } from './utils/Store';
import { createGlobalStyle } from "styled-components";
import Cookies from 'js-cookie';
import queryString from 'query-string';

import LoginPage from "./pages/00_login/LoginPage";
import Home from "./pages/archive_v1/99_home/Home";


import V2User from "./pages/2023071701_user";
import V2StudyCafe from "./pages/2024111901_studycafe";
import V2Challenge from "./pages/2023071702_challenge"
import V2ChallengeDaily from "./pages/2023071703_challenge_daily"
import V2Record from "./pages/2023071704_record"
import V2CreditWithdrawal from "./pages/2023071705_credit_witdrawal"

import V2UserDeleted from "./pages/2023071706_user_deleted"
import V2CreditHistory from "./pages/2023071707_credit_history"
import V2ChallengeCoupon from "./pages/2023071708_challenge_coupon"
import V2PushMessage from "./pages/2023071801_pushmessage"
import V2Statistices from "./pages/2023071802_statistics"
import V2Variable from "./pages/2023071803_variable"

// const SITE_IMAGE = appImages.logo0;
function App() {
  const is_login = localStorage.getItem("parttimestydy_admin__access__token") ? true : false;

  // TODO remove user info(token )
  // localStorage.removeItem("parttimestydy_admin__access__token");
  useEffect(() => {
    const login1 = localStorage.getItem("parttimestydy_admin__access__token");
    if (is_login) { return; }
  }, [is_login]);


  return (
    <StoreProvider  >
      <Router>
        <Topbar is_login={is_login} />
        <div className='container'>
          <Sidebar is_login={is_login} />
          <div style={{width:'100%', minHeight: 'calc(100vh - 100px)', padding:'25px'}}>
          {is_login == false
            ?<Switch>
              <Route path="/"><LoginPage /></Route>
            </Switch>
            :
            <Switch>
              <Route exact path="/"><Home /></Route>
              <Route path="/v2/user"><V2User /></Route>
              <Route path="/v2/studycafe"><V2StudyCafe /></Route>
              <Route path="/v2/challenge"><V2Challenge /></Route>
              <Route path="/v2/challenge-daily"><V2ChallengeDaily /></Route>
              <Route path="/v2/record"><V2Record /></Route>
              <Route path="/v2/credit-withdrawal"><V2CreditWithdrawal /></Route>
              <Route path="/v2/user-deleted"><V2UserDeleted /></Route>
              <Route path="/v2/credit-history"><V2CreditHistory /></Route>
              <Route path="/v2/challenge-coupon"><V2ChallengeCoupon /></Route>
              <Route path="/v2/push-message"><V2PushMessage /></Route>
              <Route path="/v2/statistic"><V2Statistices /></Route>
              <Route path="/v2/variable"><V2Variable /></Route>
              <Route path="/v2/external-link"></Route>
            </Switch>
          }
          </div>
        </div>
      </Router>
    </StoreProvider>
  );
}

export default App;
