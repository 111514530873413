import moment from "moment";
import axios from "axios";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Store} from '../utils/Store';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import {
    FilterButton,
    FilterButtonSmall,
    FilterCheckbox,
    FilterDateInput,
    FilterDropdownInput,
    FilterLine,
    FilterTextInput,
    FilterTextLabel
} from "../components/filter/Filter";
import PageTable from "../components/pageTable/PageTable";
import {Popup, PopupButtonBig, PopupButtonSmall, PopupInputText} from "../components/popup/Popup";

export default function RecordPage() {
    // Authentication
    const loginToken = localStorage.getItem("parttimestydy_admin__access__token");
    const {state, dispatch} = useContext(Store);

    /////////////
    // Get List
    /////////////

    // Filter
    const getUrlParams = query => Array.from(new URLSearchParams(query)).reduce((p, [k, v]) => Object.assign({}, p, {[k]: p[k] ? (Array.isArray(p[k]) ? p[k] : [p[k]]).concat(v) : v}), {});
    const isNull = (v) => {
        return (v === undefined || v === null) ? true : false;
    }
    const params = getUrlParams(window.location.search);

    const [filterName, setFilterName] = useState(params.filterName || "");
    const [filterPhone, setFilterPhone] = useState(params.filterPhone || "");
    const [filterStatusRecording, setFilterStatuRecording] = useState(params.filterStatusRecording || 1);
    const [filterReanalysisRequestedOnly, setFilterReanalysisRequestedOnly] = useState(params.filterReanalysisRequestedOnly == "true");
    const [filterDate, setFilterDate] = useState(params.filterDate || "");
    const [filterUserGroup, setFilterUserGroup] = useState(params.filterUserGroup || "");
    const filterUserGroupOptions = {
        "": "",
    }
    const [filterPageNum, setFilterPageNum] = useState(1);
    const [filterPageSize, setFilterPageSize] = useState(100);
    // ResultData
    const [resultDatas, setResultDatas] = useState([]);
    const [resultDataCount, setResultDataCount] = useState(0);
    const [resultDataPageNum, setResultDataPageNum] = useState(0);
    const [resultDataPageCount, setResultDataPageCount] = useState(0);

    // View Option
    const [viewOptionNum, setViewOptionNum] = useState(isNull(params.viewOptionNum) ? 3 : parseInt(params.viewOptionNum));
    // View -> Filter
    const FilterComponent = <div
        style={{borderRadius: "10px", padding: "10px 10px", boxShadow: "0px 1px 5px lightgray"}}>
        <FilterLine>
            <FilterTextInput placeholder="이름" onChange={(e) => {
                setFilterName(e.target.value)
            }} value={filterName}></FilterTextInput>
            <FilterTextInput placeholder="전화번호" onChange={(e) => {
                setFilterPhone(e.target.value)
            }} value={filterPhone}></FilterTextInput>
            <FilterDateInput placeholder="일자 (yyyy-mm-dd)" onChange={(e) => {
                setFilterDate(e.target.value)
            }} value={filterDate}/>
            <FilterDropdownInput
                placeholder="촬영 종료"
                options={["촬영 종료", "촬영 진행 중"]}
                value={filterStatusRecording == 1 ? "촬영 종료" : "촬영 진행 중"}
                onChange={(e) => {
                    e.target.value == "촬영 종료" ? setFilterStatuRecording(1) : setFilterStatuRecording(2);
                }}
            />
            <FilterCheckbox onChange={e => {
                setFilterReanalysisRequestedOnly(e)
            }} checked={filterReanalysisRequestedOnly}> 재검수 요청만 보기 </FilterCheckbox>
            <FilterButton onClick={() => {
                updateResultData()
            }}>조회</FilterButton>
        </FilterLine>
        <FilterLine>
            <FilterDropdownInput
                placeholder="보기 옵션"
                options={["복사용", "기본"]}
                value={
                    viewOptionNum == 3 ? "복사용" :
                        viewOptionNum == 4 ? "기본" :
                            "기본"
                }
                onChange={(e) => {
                    e.target.value == "복사용" ? setViewOptionNum(3) :
                        e.target.value == "기본" ? setViewOptionNum(4) :
                            setViewOptionNum(4)
                }}
            />
        </FilterLine>
    </div>

    const filterPageComponent = <>
        <FilterLine justifyContent="center">
            <FilterButtonSmall onClick={() => {
                if (filterPageNum == 1 || filterPageNum == 2) return;
                setFilterPageNum(filterPageNum - 2);
            }}>&lt;&lt;</FilterButtonSmall>
            <FilterButtonSmall onClick={() => {
                if (filterPageNum == 1) return;
                setFilterPageNum(filterPageNum - 1);
            }}>&lt;</FilterButtonSmall>
            <FilterTextLabel> {resultDataPageNum} / {resultDataPageCount} </FilterTextLabel>
            <FilterButtonSmall onClick={() => {
                if (filterPageNum >= resultDataPageCount) return;
                setFilterPageNum(filterPageNum + 1);
            }}>&gt;</FilterButtonSmall>
            <FilterButtonSmall onClick={() => {
                if (filterPageNum >= resultDataPageCount - 1) return;
                setFilterPageNum(filterPageNum + 2);
            }}>&gt;&gt;</FilterButtonSmall>
        </FilterLine>
    </>

    function createUpdateResultDateQueryPart() {
        let filterQuery = `?pageSize=${filterPageSize}&pageNum=${parseInt(filterPageNum) - 1}`;
        filterQuery += "&recording=" + filterStatusRecording;
        if (filterReanalysisRequestedOnly == true) filterQuery += "&reanalyzeRequest=true"
        if (filterName !== "") filterQuery += "&name=" + filterName;
        if (filterDate !== "" && filterDate.length == 10) filterQuery += "&date=" + filterDate;
        if (filterUserGroup == "" && filterPhone !== "" && "010".indexOf(filterPhone.trim()) < 0) filterQuery += "&phone=" + filterPhone;
        if (filterUserGroup !== "") {
            let phoneList = filterUserGroupOptions[filterUserGroup].split("\n").filter(ph => ph != "" | ph != null).map((e) => e.replaceAll("-", ""));
            if (filterPhone !== "" && phoneList.includes(filterPhone.replaceAll("-", ""))) filterQuery += "&phone=" + filterPhone;
            if (filterPhone !== "" && !phoneList.includes(filterPhone.replaceAll("-", ""))) filterQuery += "&phone=01099999999";
            if (filterPhone === "") {
                filterQuery += phoneList.map((e) => "&phone=" + e).join("")
            }
        }
        return filterQuery;
    }

    const lastAbortController = useRef();

    // Filter -> ResultData
    function updateResultData() {
        let filterQuery = createUpdateResultDateQueryPart();
        let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/records` + filterQuery;
        if (state.userToken == "" && loginToken != "") state.userToken = loginToken

        // setResultDatas([]);
        if (lastAbortController.current) {
            lastAbortController.current.abort();
        }
        const currentAbortController = new AbortController();
        lastAbortController.current = currentAbortController;
        const currentPromize = axios({
            method: 'GET', url: url,
            headers: {"Authorization": "Bearer " + state.userToken, "Content-Type": `application/json`},
            data: null,
            signal: currentAbortController.signal
        });
        currentPromize.then(res => {
            // 후에 들어온 요청부다 늦은 경우 그냥 반환하기
            if (res && res.data && res.data.content) {
                setResultDatas(res.data.content);
                res.data.totalElements > 999999 ? setResultDataCount(Infinity) : setResultDataCount(res.data.totalElements);
                res.data.totalPages > 999999 ? setResultDataPageCount(Infinity) : setResultDataPageCount(res.data.totalPages);
                setResultDataPageNum(filterPageNum);
                // NotificationManager.success("데이터가 다시 조회되었습니다.", "조회되었습니다.");
            } else NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
        }).catch(e => {
            if (e.message == 'canceled') return;
            NotificationManager.error(e.name + ": " + e.message, "오류 발생!");
        })
    }

    function updateResultDataRow(value) {
        var origin1 = [...resultDatas];
        var index1 = resultDatas.findIndex((iter) => {
            if (iter.recordId == value.recordId) {
                return iter
            }
        });
        origin1[index1] = value
        setResultDatas(origin1);
    }

    async function updateSingleDataByIdx(studyrecordId) {
        let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/records/${studyrecordId}`;
        if (state.userToken == "" && loginToken != "") state.userToken = loginToken
        let res = await axios({
            method: 'GET', url: url,
            headers: {"Authorization": "Bearer " + state.userToken, "Content-Type": `application/json`},
            data: {"studyMin": parseInt(selectedPopupInputStudyMinute)},
        }).catch(e => {
            NotificationManager.error(e.message, "오류발생");
            throw e;
        })
        if (res.status < 200 || res.status >= 300) {
            NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
            return null;
        }
        let value = res.data;
        var origin1 = [...resultDatas];
        var index1 = resultDatas.findIndex((iter) => {
            if (iter.recordId == value.recordId) {
                return iter
            }
        });
        origin1[index1] = value
        setResultDatas(origin1);
        return value;
    }

    useEffect(() => {
        if (filterPageNum != 1) {
            setFilterPageNum(1);
            return;
        }
        updateResultData();
    }, [filterName, filterPhone, filterDate, filterStatusRecording, filterReanalysisRequestedOnly, filterUserGroup, filterPageSize])
    useEffect(() => {
        updateResultData();
    }, [filterPageNum])

    useEffect(() => {
        const param = new URLSearchParams({
            filterName,
            filterPhone,
            filterDate,
            filterStatusRecording,
            filterReanalysisRequestedOnly,
            filterUserGroup,
            viewOptionNum
        }).toString();
        window.history.pushState(
            {
                filterName,
                filterPhone,
                filterDate,
                filterStatusRecording,
                filterReanalysisRequestedOnly,
                filterUserGroup,
                viewOptionNum
            },
            "영상 검수", window.location.pathname + "?" + param
        )
    }, [filterName, filterPhone, filterDate, filterStatusRecording, filterReanalysisRequestedOnly, filterUserGroup, viewOptionNum]);

    function handleDownload(url, filename) {
        const link = document.createElement("a");
        link.href = url;
        link.click();
    }

    const defaultThList = [
        "번호", "지점명", "영상명",
        "촬영 시작", "마지막 사진", "촬영 종료",
        "이름", "번호",
        "촬영 시간", "공부 시간",
        "다운", "상세", "체크"
    ]
    const defaultDataKeyList = [
        "recordId", "studyCafeName", "challengeAndVideoName",
        "startedTime", "lastUploadedTime", "doneTime",
        "name", "phone",
        "recordMinute", "studyMinuteString",
        "download", "detail", "checkBox"
    ]

    const viewOption3ThList = [
        "번호", "지점명", "영상명", "시작", "마지막 업로드", "종료",
        "이름", "번호",
        "촬영 시간", "공부시간",
        "OLD", "C1", "C2U", "C2D", "YU", "YD", "ADM",
        "다운", "상세", "체크"
    ]
    const viewOption3KeyList = [
        "recordId", "studyCafeName", "videoNameStr", "startedTime", "lastUploadedTime", "doneTime",
        "name", "phone",
        "recordMinute", "studyMinuteString",
        "C00", "C01", "C02U", "C02D", "YU", "YD", "ADM",
        "download", "detail",
        "checkBox"
    ]

    const viewOption4ThList = [
        "번호", "지점명", "챌린지명/영상명", "시작", "마지막 업로드", "종료",
        "이름", "번호",
        "촬영 시간", "공부시간",
        "OLD", "C1", "C2U", "C2D", "YU", "YD", "ADM",
        "다운", "상세", "체크"
    ]

    const viewOption4KeyList = [
        "recordId", "studyCafeName", "challengeAndVideoName", "startedTime", "lastUploadedTime", "doneTime",
        "name", "phone",
        "recordMinute", "studyMinuteString",
        "C00", "C01", "C02U", "C02D", "YU", "YD", "ADM",
        "download", "detail",
        "checkBox"
    ]

    const resultDataComponent = <>
        {`총 데이터 수 : ${resultDataCount}, 총 페이지 수 : ${resultDataPageCount}`}
        <PageTable
            thList={
                viewOptionNum == 0 ? defaultThList
                    : viewOptionNum == 1 ? ["번호", "지점명", "영상명", /*"시작일",*/ "촬영 시작", "마지막 사진", "촬영 종료", "이름", "번호", /*"상태",*/  "촬영 시간", "최초검수", "재검수", "다운", "상세", "체크"]
                        : viewOptionNum == 2 ? ["번호", "지점명", "영상명", /*"시작일",*/ "촬영 시작", "마지막 사진", "촬영 종료", "이름", "번호", "상태", "프레임시간", "촬영 시간", "최초검수", "재검수", "다운", "상세", "체크"]
                            : viewOptionNum == 3 ? viewOption3ThList
                                : viewOptionNum == 4 ? viewOption4ThList
                                    : defaultThList
            }
            dataKeyList={
                viewOptionNum == 0 ? defaultDataKeyList
                    : viewOptionNum == 1 ? ["recordId", "studyCafeName", "challengeAndVideoName", /*"date",*/ "startedTime", "lastUploadedTime", "doneTime", "name", "phone",/* "recordingStatus",*/ "recordMinute", "firstAnalysisStudyMinString", "lastAnalysisStudyMinString", "download", "detail", "checkBox"]
                        : viewOptionNum == 2 ? ["recordId", "studyCafeName", "challengeAndVideoName", /*"date",*/ "startedTime", "lastUploadedTime", "doneTime", "name", "phone", "status", "captureMinute", "recordMinute", "firstAnalysisStudyMinString", "lastAnalysisStudyMinString", "download", "detail", "checkBox"]
                            : viewOptionNum == 3 ? viewOption3KeyList
                                : viewOptionNum == 4 ? viewOption4KeyList
                                    : defaultDataKeyList
            }
            dataList={resultDatas.map((value) => {
                value.studyMinute = value.recording == true ? "-" : Math.ceil(value.studySecond / 60);
                value.studyMinuteString = value.recording == true ? "진행 중" : value.analysisSuccess == false ? "분석 오류" : value.studyMinute == null ? "검수 중" : value.studyMinute;
                value.videoNameStr = value.recording == true ? "촬영 중 영상" : value.videoName;
                value.studyCafeName = value.studyCafeName
                value.challengeAndVideoName =
                    <div style={{paddingBlock: "10px"}}>
                        <font style={{color: "#00000055"}}>{value.challengeName}</font><br/>
                        {value.recording == true ? "촬영 중 영상" : value.videoName}
                    </div>
                value.startedTime = moment(value.startedAt).format("MM-DD HH:mm")
                value.lastUploadedTime = value.lastUploadedAt == null ? "-" : moment(value.lastUploadedAt).format("MM-DD HH:mm")
                value.doneTime = value.recording == true ? "-" : moment(value.doneAt).format("MM-DD HH:mm");
                value.recordingStatus = value.recording == true ? <>{" "}</> : <>{"완료"}</>
                value.captureMinute = Math.ceil(value.frameCount / 6);
                value.recordMinute = Math.ceil(value.recordSeconds / 60);
                // value.studyMinute = Math.ceil(value.studySecond / 60);
                value.firstAnalysisStudyMinString =
                    value.firstAnalysisStudySecond == -1 ? (<font style={{color: "red"}}>실패</font>) :
                        value.studyMinute == null ? (<font style={{color: "red"}}>검수중</font>) :
                            value.firstAnalysisStudySecond == null ? "-" :
                                Math.ceil(value.firstAnalysisStudySecond / 60.0);
                value.lastAnalysisStudyMinString = (value.lastAnalysisStudySecond == null) ? "-" : value.lastAnalysisStudyMinString = Math.ceil(value.lastAnalysisStudySecond / 60.0)
                value.detail = (<div className="button" onClick={async () => {
                    let res = await updateSingleDataByIdx(value.recordId);
                    openResultDataSelectedPopup(res);
                }}>상세</div>);
                value.download = (value.status == "VIDEO_GENERATING")
                    ? (<div className="button-disabled" onClick={() => {
                    }}>.</div>)
                    : (<div className="button" onClick={async () => {
                        let res = await updateSingleDataByIdx(value.recordId);
                        if (res.videoUrl == null) {
                            NotificationManager.error(res.message, "아직 영상이 생성되지 않았습니다!");
                            return;
                        }
                        handleDownload(res.videoUrl, `download${moment("YYYYMMDDHHmmss")}.mp4`)
                    }}>다운</div>);
                value.checkBox = value.checked == false ? (<div className="button-unchecked" onClick={() => {
                        updateChecked(value.recordId, true);
                        value.checked = true;
                        updateResultDataRow(value);
                    }}>.</div>)
                    : value.nonStudyExceedNPercent == true ? (<div className="button-checked-2" onClick={() => {
                            updateChecked(value.recordId, false);
                            value.checked = false;
                            updateResultDataRow(value);
                        }}>√</div>)
                        : (<div className="button-checked" onClick={() => {
                            updateChecked(value.recordId, false);
                            value.checked = false;
                            updateResultDataRow(value);
                        }}>√</div>);
                value.trClassName = "";

                // if (value.nonStudyExceedNPercent == true && value.checked == false) value.trClassName = 'background-yellow';
                // if (value.nonStudyExceedNPercent == true && value.checked == false && value.inCurrentStudyProject == false) value.trClassName = "background-red";


                if (value.studyMinute == null && moment(value.doneAt).isSameOrBefore(moment().subtract(1, "minutes")))
                    value.trClassName = "background-blue-light"
                if (value.studyMinute == null && moment(value.doneAt).isSameOrBefore(moment().subtract(15, "minutes")))
                    value.trClassName = "background-blue"

                let nonStudyExists = true;
                if (value.studyMinute == null) nonStudyExists = false;
                if (value.recordMinute <= 1) nonStudyExists = false;
                if (value.studyMinute >= value.recordMinute) nonStudyExists = false;
                if (nonStudyExists == true && value.checked == false) value.trClassName = 'background-yellow';
                if (nonStudyExists == true && value.checked == false && value.inCurrentStudyProject == false) value.trClassName = 'background-red';

                value.C00 = value.reanalysisRequestCount >= 0 ? "-" : ""
                value.C01 = value.analysisHistory[0] != null ?
                    <a href={`${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/records/${value.recordId}/cnn-01-result-detail.csv`}>{Math.ceil(value.analysisHistory[0]?.studySecond / 60)}</a> : ""
                value.C02U = value.analysisHistory[1] != null && value.analysisHistory[1]?.inferenceAlgorithm == "SCENE_2_UP" ?
                    <a href={`${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/records/${value.recordId}/cnn-02-01-result-detail.csv`}>{Math.ceil(value.analysisHistory[1]?.studySecond / 60)}</a> : ""
                value.C02D = value.analysisHistory[1] != null && value.analysisHistory[1]?.inferenceAlgorithm == "SCENE_2_DOWN" ?
                    <a href={`${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/records/${value.recordId}/cnn-02-02-result-detail.csv`}>{Math.ceil(value.analysisHistory[1]?.studySecond / 60)}</a> : ""
                value.YU = value.analysisHistory[2] != null && value.analysisHistory[2]?.inferenceAlgorithm == "SCENE_HAND_UP" ?
                    <a href={`${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/records/${value.recordId}/yolo-up-result-detail.csv`}>{Math.ceil(value.analysisHistory[2]?.studySecond / 60)}</a> : ""
                value.YD = value.analysisHistory[2] != null && value.analysisHistory[2]?.inferenceAlgorithm == "SCENE_HAND_DOWN" ?
                    <a href={`${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/records/${value.recordId}/yolo-dw-result-detail.csv`}>{Math.ceil(value.analysisHistory[2]?.studySecond / 60)}</a> : ""
                value.ADM = value.analysisHistory[2] != null && value.analysisHistory[2]?.inferenceAlgorithm == "ADMIN_REANALYSIS" ? Math.ceil(value.analysisHistory[2]?.studySecond / 60)
                    : value.ADM = value.analysisHistory[3] != null && value.analysisHistory[3]?.inferenceAlgorithm == "ADMIN_REANALYSIS" ? Math.ceil(value.analysisHistory[3]?.studySecond / 60) : ""
                if (value.analysisHistory.length > 3) {
                    for (let i = value.analysisHistory.length - 1; i >= 0; i--) {
                        if (value.analysisHistory[i]?.inferenceAlgorithm === "ADMIN_REANALYSIS") {
                            value.ADM = Math.ceil(value.analysisHistory[i].studySecond / 60);
                            break;
                        }
                    }
                }
                if (value.analysisHistory.length == 0) value.C00 = value.firstAnalysisStudyMinString;

                value.lastAlgorithm = "CNN_00";
                if (value.analysisHistory.length > 0) value.lastAlgorithm = value.analysisHistory[0].algorithm;


                if (value.reanalyzeRequested) {
                    let autoReanalysis = false;
                    let reanalysisRequestedDate = new Date(Date.parse(value.lastReanalyzeRequestedAt));
                    let reanalysisRequestedAtWeekend = reanalysisRequestedDate.getDay() == 0 || reanalysisRequestedDate.getDay() == 6;
                    if (value.lastAlgorithm == "CNN_01") autoReanalysis = true;
                    if (value.lastAlgorithm == "CNN_02_01" && reanalysisRequestedAtWeekend) autoReanalysis = true;
                    if (value.lastAlgorithm == "CNN_02_02" && reanalysisRequestedAtWeekend) autoReanalysis = true;
                    // if(value.lastAlgorithm == "CNN_02_01" || value.lastAlgorithm == "CNN_02_02") value.trClassName = "background-light-gray";
                    if (autoReanalysis == true) value.trClassName = "background-gray";
                }
                return value;
            })}
        />
    </>


    function updateChecked(recordId, check) {
        var url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/records/${recordId}/check`;
        if (state.userToken == "" && loginToken != "") state.userToken = loginToken
        axios({
            method: 'PUT', url: url,
            headers: {"Authorization": "Bearer " + state.userToken, "Content-Type": `application/json`},
            data: {"check": check},
        }).then(res => {
            if (res.status < 200 || res.status >= 300) {
                NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
                return;
            }
            // NotificationManager.success("체크되었습니다.", "체크되었습니다.");
        })
            .catch(e => {
                NotificationManager.error(e.message, "오류발생");
                throw e;
            })
    }

    /////////////
    // resultDataSelected
    /////////////
    const [resultDataSelected, setResultDataSelected] = useState({});
    const [selectedPopupInputStudyMinute, setSelectedPopupInputStudyMinute] = useState(0);

    async function openResultDataSelectedPopup(value) {
        setResultDataSelected(value);
        setSelectedPopupInputStudyMinute("");
    }

    function updateAndCloseSelectedPopup() {
        updateResultDataRow(resultDataSelected);
        setResultDataSelected({});
    }

    function closeSelectedPopupAnRefresh() {
        setResultDataSelected({});
        updateResultData();
    }

    function updateStudyMinute() {
        let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/records/${resultDataSelected.recordId}/study-time`;
        if (state.userToken == "" && loginToken != "") state.userToken = loginToken
        axios({
            method: 'PUT', url: url,
            headers: {"Authorization": "Bearer " + state.userToken, "Content-Type": `application/json`},
            data: {"studyMinutes": parseInt(selectedPopupInputStudyMinute)},
        }).then(res => {
            if (res.status < 200 || res.status >= 300) {
                NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
                return;
            }
            NotificationManager.success("공부 시간이 변경되었습니다.", "반영되었습니다.");

            if (!filterReanalysisRequestedOnly) {
                resultDataSelected.lastAnalysisStudySecond = parseInt(selectedPopupInputStudyMinute) * 60;
                resultDataSelected.studyMinute = parseInt(selectedPopupInputStudyMinute);
                // updateAndCloseSelectedPopup(resultDataSelected);
                closeSelectedPopupAnRefresh();
            }
            if (filterReanalysisRequestedOnly) {
                updateChecked(resultDataSelected.recordId, true);
                closeSelectedPopupAnRefresh();
            }

        })
            .catch(e => {
                NotificationManager.error(e.message, "오류발생");
                throw e;
            })
    }

    function finishRecord() {
        let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/records/${resultDataSelected.recordId}/finish`;
        if (state.userToken == "" && loginToken != "") state.userToken = loginToken
        axios({
            method: 'POST', url: url,
            headers: {"Authorization": "Bearer " + state.userToken, "Content-Type": `application/json`},
        }).then(res => {
            if (res.status < 200 || res.status >= 300) {
                NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
                return;
            }
            NotificationManager.success("촬영이 종료되었습니다.", "종료되었습니다.");
        })
            .catch(e => {
                NotificationManager.error(e.message, "오류발생");
                throw e;
            })
    }

    function createVideo() {
        let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/records/${resultDataSelected.recordId}/videos`;
        if (state.userToken == "" && loginToken != "") state.userToken = loginToken
        axios({
            method: 'POST', url: url,
            headers: {"Authorization": "Bearer " + state.userToken, "Content-Type": `application/json`},
        }).then(res => {
            if (res.status < 200 || res.status >= 300) {
                NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
                return;
            }
            NotificationManager.success("영상 생성 요청이 되었습니다.", "생성 요청되었습니다.");
        })
            .catch(e => {
                NotificationManager.error(e.message, "오류발생");
                throw e;
            })
    }

    function reanalyzeVideoScene() {
        let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/records/${resultDataSelected.recordId}/reanalysis`;
        if (state.userToken == "" && loginToken != "") state.userToken = loginToken
        axios({
            method: 'POST', url: url,
            headers: {"Authorization": "Bearer " + state.userToken, "Content-Type": `application/json`},
        }).then(res => {
            if (res.status < 200 || res.status >= 300) {
                NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
                return;
            }
            NotificationManager.success("1차 재검수 요청되었습니다.", "재검수 요청되었습니다.");
        })
            .catch(e => {
                NotificationManager.error(e.message, "오류발생");
                throw e;
            })
    }

    // Data -> View
    const selectedPopUp = <Popup enabled={Object.keys(resultDataSelected).length} closeCallback={() => {
        updateAndCloseSelectedPopup(resultDataSelected)
    }}>
        <img src={resultDataSelected.thumbnailUrl} style={{width: "400px"}}/><br/>

        {/*<PopupInputText name="상태" value={resultDataSelected.status} /><br/>*/}
        <PopupInputText name="번호" value={resultDataSelected.recordId}></PopupInputText> <br/>
        <PopupInputText name="이름" value={resultDataSelected.name}/><br/>
        <PopupInputText name="휴대폰" value={resultDataSelected.phone}/><br/>
        <PopupInputText name="영상명" value={resultDataSelected.videoName}/><br/>
        <PopupInputText name="재검수 요청 횟수" value={resultDataSelected.reanalysisRequestCount} width={'fit-content'}/><br/>
        <PopupInputText name="재검수 요청" value={resultDataSelected.reanalyzeRequested ? "요청됨" : "-"}
                        color={resultDataSelected.reanalyzeRequested ? 'red' : null}/><br/>

        <div style={{height: '16px'}}></div>
        <div style={{display: 'flex'}}>
            <PopupButtonSmall onClick={() => {
                finishRecord()
            }}>촬영 강제 종료</PopupButtonSmall>
            <PopupButtonSmall onClick={() => {
                createVideo()
            }}>영상 생성 재요청</PopupButtonSmall>
            <PopupButtonSmall onClick={() => {
                reanalyzeVideoScene()
            }}>1차 검수 재요청</PopupButtonSmall>
        </div>

        <div style={{height: '16px'}}></div>
        <div style={{height: '120px', display: 'flex'}}>
            <div style={{width: '50%', height: '100%'}}>
                <div style={{width: '100%', textAlign: "center"}}>촬영 시간(분)</div>
                <div style={{
                    width: '100%',
                    textAlign: "center",
                    fontSize: "80px"
                }}>{resultDataSelected.recordMinute}</div>
            </div>
            <div style={{width: '50%', height: '100%'}}>
                <div style={{width: '100%', textAlign: "center"}}>공부 시간(분)</div>
                <div style={{
                    width: '100%',
                    textAlign: "center",
                    fontSize: "80px"
                }}>{resultDataSelected.studyMinute}</div>
            </div>
        </div>
        <div style={{height: '12px'}}></div>
        <PopupInputText name="달성 시간 (분)" value={selectedPopupInputStudyMinute} onChange={e => {
            setSelectedPopupInputStudyMinute(e.target.value)
        }} width={'200px'} fontSize={'24px'}/>
        <PopupButtonBig onClick={() => {
            updateStudyMinute();
        }} fontSize={'20px'}>달성 시간 변경{filterReanalysisRequestedOnly && "(+ 자동 체크)"}</PopupButtonBig>
    </Popup>

/////////////
// Create One
/////////////

    return (
        <>
            {FilterComponent}
            <div style={{height: "20px"}}></div>
            {resultDataComponent}
            <div style={{height: "10px"}}></div>
            {filterPageComponent}
            {selectedPopUp}
            <NotificationContainer/>
        </>
    );
}
