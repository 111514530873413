import moment from "moment";
import axios from "axios";
import React, {useContext, useEffect, useState} from "react";
import {Store} from '../utils/Store';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import {FilterButtonSmall, FilterLine, FilterTextLabel} from "../components/filter/Filter";
import PageTable from "../components/pageTable/PageTable";

export default function StudyCafePage() {
    // Authentication
    const loginToken = localStorage.getItem("parttimestydy_admin__access__token");
    const {state} = useContext(Store);

    /////////////
    // Get List
    /////////////

    // Filter
    const [filterName, setFilterName] = useState("");
    const [filterPhone, setFilterPhone] = useState("");

    const [filterPageNum, setFilterPageNum] = useState(1);
    const [filterPageSize, setFilterPageSize] = useState(100);

    // ResultData
    const [resultDatas, setResultDatas] = useState([]);
    const [resultDataCount, setResultDataCount] = useState(0);
    const [resultDataPageNum, setResultDataPageNum] = useState(0);
    const [resultDataPageCount, setResultDataPageCount] = useState(0);

    // View -> Filter

    const filterPageComponent = <>
        <FilterLine justifyContent="center">
            <FilterButtonSmall onClick={() => {
                if (filterPageNum == 1 || filterPageNum == 2) return;
                setFilterPageNum(filterPageNum - 2);
            }}>&lt;&lt;</FilterButtonSmall>
            <FilterButtonSmall onClick={() => {
                if (filterPageNum == 1) return;
                setFilterPageNum(filterPageNum - 1);
            }}>&lt;</FilterButtonSmall>
            <FilterTextLabel> {resultDataPageNum} / {resultDataPageCount} </FilterTextLabel>
            <FilterButtonSmall onClick={() => {
                if (filterPageNum >= resultDataPageCount) return;
                setFilterPageNum(filterPageNum + 1);
            }}>&gt;</FilterButtonSmall>
            <FilterButtonSmall onClick={() => {
                if (filterPageNum >= resultDataPageCount - 1) return;
                setFilterPageNum(filterPageNum + 2);
            }}>&gt;&gt;</FilterButtonSmall>
        </FilterLine>
    </>

    // Filter -> ResultData
    function updateResultData() {
        let filterQuery = `?pageSize=${filterPageSize}&pageNum=${parseInt(filterPageNum) - 1}`;
        console.log('filterQuery:', filterQuery)
        let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/all-study-cafe-paging` + filterQuery;
        if (state.userToken == "" && loginToken != "") state.userToken = loginToken
        axios({
            method: 'GET', url: url,
            headers: {"Authorization": "Bearer " + state.userToken, "Content-Type": `application/json`},
            data: null,
        }).then(res => {
            if (res && res.data && res.data.content) {
                console.log('res2: ', res.data.content)
                setResultDatas(res.data.content);
                setResultDataCount(res.data.totalElements);
                setResultDataPageCount(res.data.totalPages);
                setResultDataPageNum(filterPageNum);
            } else NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
        })
            .catch(e => {
                NotificationManager.error(e.message, "오류 발생!");
            })
    }

    useEffect(() => {
        if (filterPageNum == 1) updateResultData(); else setFilterPageNum(1)
    }, [filterName, filterPhone, filterPageSize])
    useEffect(() => {
        updateResultData()
    }, [filterPageNum])

    const defaultThList = ["지점번호", "지점명", "지역명"]
    const defaultDataKeyList = ["studyCafeId", "studyCafeName", "regionName"]
    const resultDataComponent = <>
        {`총 데이터 수 : ${resultDataCount}, 총 페이지 수 : ${resultDataPageCount}`}
        <PageTable
            thList={defaultThList}
            dataKeyList={defaultDataKeyList}
            dataList={resultDatas.map((value) => {
                value.changedAtString = moment(value.changedAt).format("YYYY-MM-DD HH:mm:ss")
                return value;
            })}
        />
    </>


    return (
        <>
            <div style={{height: "20px"}}></div>
            {resultDataComponent}
            <div style={{height: "10px"}}></div>
            {filterPageComponent}
            <NotificationContainer/>
        </>
    );
}
