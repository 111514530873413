import moment from "moment";
import axios from "axios";
import React, {useContext, useEffect, useState} from "react";
import {Store} from '../utils/Store';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import {
    FilterButton,
    FilterButtonSmall,
    FilterDateInput,
    FilterLine,
    FilterTextInput,
    FilterTextLabel
} from "../components/filter/Filter";
import PageTable from "../components/pageTable/PageTable";
import {Popup, PopupInputText} from "../components/popup/Popup";

export default function ChallengePage() {
    // Authentication
    const loginToken = localStorage.getItem("parttimestydy_admin__access__token");
    const {state, dispatch} = useContext(Store);

    // Filter
    const [filterName, setFilterName] = useState("");
    const [filterPhone, setFilterPhone] = useState("");
    const [filterDate, setFilterDate] = useState(moment().format("YYYY-MM-DD"));
    const [filterPageNum, setFilterPageNum] = useState(1);
    const [filterPageSize, setFilterPageSize] = useState(100);

    // ResultData
    const [resultDatas, setResultDatas] = useState([]);
    const [resultDataCount, setResultDataCount] = useState(0);
    const [resultDataPageNum, setResultDataPageNum] = useState(0);
    const [resultDataPageCount, setResultDataPageCount] = useState(0);
    const [resultDataRecordUserCount, setResultDataRecordUserCount] = useState(0);

    // View Option
    const [viewOptionNum, setViewOptionNum] = useState(0);

    // View -> Filter
    const FilterComponent = (
        <div style={{borderRadius: "10px", padding: "10px 10px", boxShadow: "0px 1px 5px lightgray"}}>
            <FilterLine>
                <FilterTextInput placeholder="이름" onChange={(e) => {
                    setFilterName(e.target.value)
                }} value={filterName}></FilterTextInput>
                <FilterTextInput placeholder="전화번호" onChange={(e) => {
                    setFilterPhone(e.target.value)
                }} value={filterPhone}></FilterTextInput>
                <FilterDateInput placeholder="일자 (yyyy-mm-dd)" onChange={(e) => {
                    setFilterDate(e.target.value)
                }} value={filterDate}/>
                <FilterButton onClick={() => {
                    updateResultData()
                }}>조회</FilterButton>
            </FilterLine>
        </div>
    )

    const filterPageComponent = (
        <>
            <FilterLine justifyContent="center">
                <FilterButtonSmall onClick={() => {
                    if (filterPageNum === 1 || filterPageNum === 2) return;
                    setFilterPageNum(filterPageNum - 2);
                }}>&lt;&lt;</FilterButtonSmall>
                <FilterButtonSmall onClick={() => {
                    if (filterPageNum === 1) return;
                    setFilterPageNum(filterPageNum - 1);
                }}>&lt;</FilterButtonSmall>
                <FilterTextLabel> {resultDataPageNum} / {resultDataPageCount} </FilterTextLabel>
                <FilterButtonSmall onClick={() => {
                    if (filterPageNum >= resultDataPageCount) return;
                    setFilterPageNum(filterPageNum + 1);
                }}>&gt;</FilterButtonSmall>
                <FilterButtonSmall onClick={() => {
                    if (filterPageNum >= resultDataPageCount - 1) return;
                    setFilterPageNum(filterPageNum + 2);
                }}>&gt;&gt;</FilterButtonSmall>
            </FilterLine>
        </>
    )

    // Filter -> ResultData
    function updateResultData() {
        let filterQuery = `?pageSize=${filterPageSize}&pageNum=${parseInt(filterPageNum) - 1}`;
        if (filterName !== "") filterQuery += "&name=" + filterName;
        if (filterDate !== "" && filterDate.length === 10) filterQuery += "&date=" + filterDate;
        if (filterPhone !== "") filterQuery += "&phone=" + filterPhone.replaceAll("-", "").trim();

        let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/progresses` + filterQuery;
        if (state.userToken === "" && loginToken !== "") state.userToken = loginToken;

        axios({
            method: 'GET',
            url: url,
            headers: {"Authorization": "Bearer " + state.userToken, "Content-Type": "application/json"},
            data: null,
        }).then(res => {
            if (res && res.data && res.data.content) {
                setResultDatas(res.data.content);
                setResultDataCount(res.data.totalElements);
                setResultDataPageCount(res.data.totalPages);
                setResultDataPageNum(filterPageNum);
            } else {
                NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
            }
        }).catch(e => {
            NotificationManager.error(e.message, "오류 발생!");
        })
    }

    function updateResultDataRow(value) {
        var origin1 = [...resultDatas];
        var index1 = resultDatas.findIndex((iter) => {
            if (iter.id === value.id) {
                return iter
            }
        });
        origin1[index1] = value;
        setResultDatas(origin1);
    }

    function updateRecordUserCount() {
        let filterQuery = `?pageNum=0&pageSize=1`;
        if (filterName !== "") filterQuery += "&name=" + filterName;
        if (filterDate !== "" && filterDate.length === 10) filterQuery += "&date=" + filterDate;
        if (filterPhone !== "") filterQuery += "&phone=" + filterPhone.replaceAll("-", "").trim();

        let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/progresses` + filterQuery;
        if (state.userToken === "" && loginToken !== "") state.userToken = loginToken;

        axios({
            method: 'GET',
            url: url,
            headers: {"Authorization": "Bearer " + state.userToken, "Content-Type": "application/json"},
            data: null,
        }).then(res => {
            if (res.status === 200) {
                // alert(res.data.totalElements)
                console.log('res: ', res.data)
                console.log('res.data: ', res.data)
                console.log('res.data.content.count: ', res.data.content.count)
                console.log('res.data.totalElements: ', res.data.totalElements)
                console.log('res.data.totalPages: ', res.data.totalPages)
                setResultDataRecordUserCount(res.data.totalElements);
            } else {
                NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
            }
        }).catch(e => {
            NotificationManager.error(e.message, "오류 발생!");
        })
    }
    useEffect(() => {
        if (filterPageNum === 1) updateResultData(); else setFilterPageNum(1);
    }, [filterName, filterPhone, filterDate, filterPageSize]);

    useEffect(() => {
        updateRecordUserCount()
    }, [filterName, filterPhone, filterDate]);
    useEffect(() => {
        updateResultData()
    }, [filterPageNum]);

    const defaultThList = [
        "날짜", "지점명",
        "이름", "휴대폰",
        "공부 시간",
        "촬영 영상 개수"
    ]
    const defaultDataKeyList = [
        "date", "studyCafeName",
        "name", "phone",
        "studyMinutes",
        "recordsCount"
    ]

    const resultDataComponent = (
        <>
            {`총 페이지 수: ${resultDataPageCount}, 공부한 유저 수: ${resultDataCount}`}
            <PageTable
                thList={defaultThList}
                dataKeyList={defaultDataKeyList}
                dataList={resultDatas.map((value) => {
                    value.detail = (
                        <div className="button" onClick={() => {
                            openResultDataSelectedPopup(value);
                        }}>
                            .
                        </div>
                    );
                    return value;
                })}
            />
        </>
    )

    /////////////
    // By One
    /////////////
    const [resultDataSelected, setResultDataSelected] = useState({});
    const [selectedPopupInputStudyMinute, setSelectedPopupInputStudyMinute] = useState("");

    async function openResultDataSelectedPopup(value) {
        setSelectedPopupInputStudyMinute("");
        setResultDataSelected(value);
    }

    function closeSelectedPopupAndReflect() {
        updateResultDataRow(resultDataSelected);
        setResultDataSelected({});
    }

    // Data -> View
    const selectedPopUp = (
        <Popup enabled={Object.keys(resultDataSelected).length > 0} closeCallback={() => {
            closeSelectedPopupAndReflect()
        }}>
            <PopupInputText name="번호" value={resultDataSelected.id}/><br/>
            <PopupInputText name="날짜" value={resultDataSelected.date}/><br/>
            <div style={{height: '16px'}}></div>
            <div style={{height: '120px', display: 'flex'}}>
                <div style={{width: '50%', height: '100%'}}>
                </div>
            </div>
            <div style={{height: '12px'}}></div>
            {/* <PopupInputText name="달성 시간 (분)" value={selectedPopupInputStudyMinute} onChange={e => { setSelectedPopupInputStudyMinute(e.target.value) }} width={'100px'} /> */}
            {/* <PopupButtonSmall onClick={() => { updateStudyMinute() }}>변경</PopupButtonSmall><br /> */}

            {/* <PopupInputText name="추가 달성 시간 (분)" value={selectedPopupInputStudyMinute} onChange={e => { setSelectedPopupInputStudyMinute(e.target.value) }} width={'100px'} /> */}
            {/* <PopupButtonSmall onClick={() => { addStudyMinute() }}>부여</PopupButtonSmall><br /> */}
        </Popup>
    )

    return (
        <>
            {FilterComponent}
            <div style={{height: "20px"}}></div>
            {resultDataComponent}
            <div style={{height: "10px"}}></div>
            {filterPageComponent}
            {selectedPopUp}
            <NotificationContainer/>
        </>
    );
}
