import moment from "moment";
import axios from "axios";
import React, { useState, useRef, useContext, useEffect } from "react";
import { Store } from '../utils/Store';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import {FilterLine, FilterButton, FilterTextInput, FilterButtonSmall, FilterTextLabel} from "../components/filter/Filter";
import PageTable from "../components/pageTable/PageTable";
import {Popup, PopupInputText, PopupButtonBig, PopupButtonSmall} from "../components/popup/Popup";

export default function UserPage() {
  // Authentication
  const loginToken =  localStorage.getItem("parttimestydy_admin__access__token");
  const { state, dispatch } = useContext(Store);

  /////////////
  // Get List
  /////////////

  // Filter
  const [filterName, setFilterName] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [filterPageNum, setFilterPageNum] = useState(1);
  const [filterPageSize, setFilterPageSize] = useState(100);

  // ResultData
  const [resultDatas, setResultDatas] = useState([]);
  const [resultDataCount, setResultDataCount] = useState(0);
  const [resultDataPageNum, setResultDataPageNum] = useState(0);
  const [resultDataPageCount, setResultDataPageCount] = useState(0);

  // Filter -> ResultData
  function updateResultData() {
    let filterQuery = `?pageSize=${filterPageSize}&pageNum=${parseInt(filterPageNum) - 1}`;
    if(filterName !== "") filterQuery += "&name=" + filterName;
    if(filterPhone !== "" && "010".indexOf(filterPhone) < 0) filterQuery += "&phone=" + filterPhone;
    let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/users` + filterQuery;
    if (state.userToken === "" && loginToken !== "") state.userToken = loginToken
    axios({
      method: 'GET',
      url: url,
      headers: { "Authorization": "Bearer " + state.userToken, "Content-Type": `application/json` },
      data: null,
    }).then(res => {
      if (res && res.data && res.data.content) {
        setResultDatas(res.data.content);
        setResultDataCount(res.data.totalElements);
        setResultDataPageCount(res.data.totalPages);
        setResultDataPageNum(filterPageNum);
      }
      else NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
    })
        .catch(e => { NotificationManager.error(e.message, "오류 발생!"); })
  }

  useEffect(() => { setFilterPageSize(100); }, []);
  useEffect(() => { updateResultData(); }, [filterName, filterPhone, filterPageNum, filterPageSize]);

  // View -> Filter
  const FilterComponent = (
      <div style={{ borderRadius: "10px", padding: "10px 10px", boxShadow: "0px 1px 5px lightgray" }}>
        <FilterLine>
          <FilterTextInput placeholder="이름" onChange={(e) => { setFilterName(e.target.value) }} value={filterName} />
          <FilterTextInput placeholder="전화번호" onChange={(e) => { setFilterPhone(e.target.value) }} value={filterPhone} />
          <FilterButton onClick={() => { updateResultData() }}>조회</FilterButton>
          <FilterButton onClick={() => { openCreatePopup() }}>유저 생성</FilterButton>
        </FilterLine>
      </div>
  );

  const filterPageComponent = (
      <FilterLine justifyContent="center" >
        <FilterButtonSmall onClick={() => { if (filterPageNum === 1 || filterPageNum === 2) return; setFilterPageNum(filterPageNum - 2); }}>&lt;&lt;</FilterButtonSmall>
        <FilterButtonSmall onClick={() => { if (filterPageNum === 1) return; setFilterPageNum(filterPageNum - 1); }}>&lt;</FilterButtonSmall>
        <FilterTextLabel> {resultDataPageNum} / {resultDataPageCount} </FilterTextLabel>
        <FilterButtonSmall onClick={() => { if (filterPageNum >= resultDataPageCount) return; setFilterPageNum(filterPageNum + 1); }}>&gt;</FilterButtonSmall>
        <FilterButtonSmall onClick={() => { if (filterPageNum >= resultDataPageCount - 1) return; setFilterPageNum(filterPageNum + 2); }}>&gt;&gt;</FilterButtonSmall>
      </FilterLine>
  );

  // ResultData -> View
  const resultDataComponent = (
      <>
        {`총 데이터 수 : ${resultDataCount}, 총 페이지 수 : ${resultDataPageCount}`}
        <PageTable
            thList={[
              "번호", "가입날짜", "지점명",
              "이름", "번호", "보유 상금",
              "상세"
            ]}
            dataKeyList={[
              "id", "createdAt", "studyCafeName",
              "name", "phone", "credit",
              "detail"
            ]}
            dataList={resultDatas.map((value, index) => {
              const { userProfile, studyCredit, studyCafeName } = value;
              userProfile.createdAt = moment(userProfile.createdAt).format("YYYY-MM-DD HH:mm:ss");
              studyCredit.credit.amount = studyCredit.credit.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              value.detail = (<div className="button" onClick={() => { openResultDataSelectedPopup(value) }}>.</div>);
              return { ...userProfile, studyCafeName: studyCafeName,credit: studyCredit.credit.amount, detail: value.detail };
            })}
        />
      </>
  );

  /////////////
  // By One
  /////////////
  const [resultDataSelected, setResultDataSelected] = useState({});
  const [selectedPopupInputStudyCredit, setSelectedPopupInputStudyCredit] = useState("");

  function openResultDataSelectedPopup(value) {
    setSelectedPopupInputStudyCredit("");
    setResultDataSelected(value);
  }

  function closeSelectedPopupAndRefresh() {
    setResultDataSelected({});
    updateResultData();
  }

  function updateSelectedUserStudyCredit() {
    let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/users/${resultDataSelected.userProfile.id}/credits`;
    if (state.userToken === "" && loginToken !== "") state.userToken = loginToken
    axios({
      method: 'PUT',
      url: url,
      headers: { "Authorization": "Bearer " + state.userToken, "Content-Type": `application/json` },
      data: { "amount": parseInt(selectedPopupInputStudyCredit) },
    }).then(res => {
      if (res.status < 200 || res.status >= 300) {
        NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
        return;
      }
      NotificationManager.success("상금이 변경되었습니다!", "변경되었습니다!");
      closeSelectedPopupAndRefresh();
    })
        .catch(e => { NotificationManager.error(e.message, "오류발생"); })
  }

  function deleteSelectedUser() {
    let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/users/${resultDataSelected.userProfile.id}`;
    if (state.userToken === "" && loginToken !== "") state.userToken = loginToken
    axios({
      method: 'DELETE',
      url: url,
      headers: { "Authorization": "Bearer " + state.userToken, "Content-Type": `application/json` },
    }).then(res => {
      if (res.status < 200 || res.status >= 300) {
        NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
        return;
      }
      NotificationManager.success("해당 유저가 삭제되었습니다!", "삭제되었습니다!");
      closeSelectedPopupAndRefresh();
    })
        .catch(e => { NotificationManager.error(e.message, "오류발생"); })
  }

  const selectedPopUp = (
      <Popup enabled={Object.keys(resultDataSelected).length} closeCallback={() => { closeSelectedPopupAndRefresh() }}>
        <PopupInputText name="유저번호" value={resultDataSelected.userProfile?.id || ""} readOnly /><br />
        <PopupInputText name="이름" value={resultDataSelected.userProfile?.name || ""} readOnly /><br />
        <PopupInputText name="전화번호" value={resultDataSelected.userProfile?.phone || ""} readOnly /><br />
        <div style={{ height: '8px' }}></div>
        <PopupInputText name="상금" placeholder={resultDataSelected.studyCredit?.credit.amount || ""} value={selectedPopupInputStudyCredit} onChange={(e) => { setSelectedPopupInputStudyCredit(e.target.value); }} width={'150px'} /><br />
        <PopupButtonSmall onClick={() => { updateSelectedUserStudyCredit() }}>상금 변경</PopupButtonSmall><br />
        <div style={{ height: '16px' }}></div>
        <PopupButtonBig onClick={() => { deleteSelectedUser() }}>유저 삭제</PopupButtonBig>
      </Popup>
  );

  /////////////
  // Create One
  /////////////
  const [createPopup, setCreatePopup] = useState(false);
  const [createPopupInputName, setCreatePopupInputName] = useState("");
  const [createPopupInputPhone, setCreatePopupInputPhone] = useState("");
  const [createPopupInputStudyCafeNumber, setCreatePopupInputStudyCafeNumber] = useState("");

  function openCreatePopup() { setCreatePopup(true); }
  function closeCreatePopupAndRefresh() { setCreatePopup(false); updateResultData(); }

  function createUser() {
    let url = `${process.env.REACT_APP_SERVER_ROOT_URL}/admin/v1/users`;
    if (state.userToken === "" && loginToken !== "") state.userToken = loginToken
    axios({
      method: 'POST',
      url: url,
      headers: { "Authorization": "Bearer " + state.userToken, "Content-Type": `application/json` },
      data: {
        "name": createPopupInputName,
        "phone": createPopupInputPhone,
        "studyCafeId": createPopupInputStudyCafeNumber,
        "studyCredit": 0
      },
    }).then(res => {
      if (res.status < 200 || res.status >= 300) {
        NotificationManager.error(res.message, "오류발생 [" + res.status + "] ");
        return;
      }
      NotificationManager.success("유저가 생성되었습니다.", "생성되었습니다!");
      closeCreatePopupAndRefresh();
    })
        .catch(e => { NotificationManager.error(e.message, "오류발생"); })
  }

  const createPopUp = (
      <Popup enabled={createPopup} closeCallback={() => { closeCreatePopupAndRefresh() }}>
        <PopupInputText name="이름" value={createPopupInputName} onChange={e => { setCreatePopupInputName(e.target.value) }} /><br />
        <PopupInputText name="전화번호" placeholder={"기존에 가입된 전화번호로 계정생성은 불가능합니다."} value={createPopupInputPhone} onChange={e => { setCreatePopupInputPhone(e.target.value) }} /><br />
          <PopupInputText name="스터디카페 번호" value={createPopupInputStudyCafeNumber} onChange={e => { setCreatePopupInputStudyCafeNumber(e.target.value) }} /><br />
          <div style={{ height: '16px' }}></div>
        <PopupButtonBig onClick={() => { createUser() }}>유저 생성</PopupButtonBig>
      </Popup>
  );

  return (
      <>
        {FilterComponent}
        <div style={{ height: "20px" }}></div>
        {resultDataComponent}
        <div style={{ height: "10px" }}></div>
        {filterPageComponent}
        {selectedPopUp} {createPopUp}
        <NotificationContainer />
      </>
  );
}
